import axios from 'axios';
import {
  useCallback,
} from 'react';
import { baseUrl } from '../helpers/urls';
import { useCurrentUser } from '../contexts/CurrentUser.context';

const useAuth = () => {

  const {currentUser, setCurrentUser, setToken} = useCurrentUser()

  const login = useCallback(async ({email, password}) => {
    try {
      const response = await axios.post(`${baseUrl}/auth/login`, { email, password });
  
      if (response.data) {
        setCurrentUser(response.data.user);
        setToken(response.data.token);
      }
  
      return response.data;
    } catch (error) {
      if (error.response?.status === 409) {
        // Gebruiker moet eerst een wachtwoord instellen
        setCurrentUser({email})
        return { requirePasswordReset: true };
      }
  
      throw new Error(error.response?.data?.error); // Andere errors normaal laten afhandelen
    }
  }, []);

  const registerUser = useCallback(async ({firstname, lastname, email, password, passwordRepeat}) => {
    const response = await axios.post(`${baseUrl}/auth/register`, {firstname, lastname, email, password,  passwordRepeat});

    if (response.data) {
      setCurrentUser(response.data.user);
      setToken(response.data.token);
    }

    return response.data;
  }, []);

  const resetPassword = useCallback(async ({
    email, password, repeatPassword
  }) => {
    const response = await axios.post(`${baseUrl}/auth/set-password`, {
      email, password, repeatPassword
    });

    if (response.data) {
      setCurrentUser(response.data.user);
      setToken(response.data.token);
    }

    return response.data;

  }, [])

  const logout = useCallback(async () => {
    setCurrentUser(null)
    setToken(null)
  }, [])

  return {
    login,
    registerUser,
    logout,
    resetPassword
  };
};

export default useAuth;