import { useCallback } from 'react';
import { BsTrash } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useTheme } from '../../contexts/Theme.context';
import { useCurrentUser } from '../../contexts/CurrentUser.context';

export default function Wallet({ wallet, onDelete, prefix }) {
  const { theme, oppositeTheme } = useTheme();
  const { loaded, currentUser } = useCurrentUser();

  const handleClick = useCallback(() => {
    onDelete(id);
  }, [onDelete]);

  return (
    <div className={`p-3 border rounded shadow-sm d-flex align-items-center justify-content-between bg-${theme} text-${oppositeTheme} transition`}>
      <div className="flex-grow-1">
        <h5 className="mb-1 text-truncate">{wallet.name}</h5>
        <p className="mb-0 text-muted text-truncate">{wallet.description}</p>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Link 
          to={`${prefix ? `${prefix}/` : ""}wallets/${wallet.code}`} 
          className="btn btn-primary btn-sm"
        >
          Details
        </Link>
        {loaded && currentUser.permissions.includes('write') && (
          <button 
            className="btn btn-outline-danger btn-sm d-flex align-items-center justify-content-center"
            onClick={handleClick}
            title="Verwijder wallet"
          >
            <BsTrash />
          </button>
        )}
      </div>
    </div>
  );
}
