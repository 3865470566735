import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import useAuth from "../../api/auth";
import { useNavigate } from "react-router";
import { useCurrentUser } from "../../contexts/CurrentUser.context";
import { Link } from "react-router-dom";
import imgCard from "../../images/topupcard.png"

export default function Login(){

  const {register, handleSubmit, errors} = useForm();
  const {login }= useAuth();
  const [error, setError] = useState()
  const navigate = useNavigate()
  const {currentUser} = useCurrentUser()

  useEffect(() =>{
    if(currentUser) {
      navigate("/users/me")
    }
  }, [currentUser])

  const handleLogin = async (data) => {
    try{
      const result = await login(data)

      if(result.requirePasswordReset){
        navigate("/auth/pass");
        return
      }
      navigate("/users/me")
    }catch(error){
      console.log(error)
      setError(error)
    }
  }

  return <>
  <div className="container mb-4 mt-3">
    <div className="rounded-2 rounded-lg-4 shadow overflow-hidden register-container">
      <div className="row">
      <div className="col-12 col-lg-6 p-0 bg-primary">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <img src={imgCard} className="login-image"/>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="p-4 d-flex flex-column w-100 h-100 justify-content-center align-items-center">
            <h2 className="text-center">Login</h2>
            {error && <p className="fw-bold text-danger">{error.message}</p>}
            <form onSubmit={handleSubmit(handleLogin)} className="w-100">
              <div className="mt-3">
                <label>Email</label>
                <input type="email" {...register("email")} placeholder="Email" className="form-control"/>
              </div>
              <div className="mt-3">
                <label>Wachtwoord</label>
                <input type="password" {...register("password")} placeholder="Wachtwoord" className="form-control" />
              </div>
              <input type="submit" value={"Log in"} className="btn btn-primary w-100 mt-3"
              />
            </form>

            <p className="text-center mt-3">
              Nog geen account? Maak <Link to="/auth/register">hier</Link> een aan.
            </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
    
  </>
}