
export default function Forbidden() {
  return (
    <div className="container mb-4 text-center" data-cy="forbidden">
      <h1>403</h1>
      <h1>Not authorized</h1>
      <p>
        You are not authorized to see this page!
      </p>
    </div>
  );
}