import { Routes, Route } from 'react-router-dom';
import User from './components/users/User';
import NotFound from './components/errors/NotFound';
import './App.css';
import Navbar from './components/Navbar';
import CardDetail from './components/cards/CardDetail';
import HomePage from './components/HomePage';
import WalletDetail from './components/wallets/WalletDetail';
import { useTheme } from './contexts/Theme.context';
import UserForm from './components/users/UserForm';
import CardForm from './components/cards/CardForm';
import { useState } from 'react';
import Login from './components/authentication/Login';
import PasswordReset from './components/authentication/PasswordReset';
import CompanyDashboard from './components/dashboard/CompanyDashboard';
import Register from './components/authentication/Register';
import WalletDashboard from './components/dashboard/WalletDashboard';

function App() {

  const { theme, oppositeTheme } = useTheme();
  const [showNav, setShowNav] = useState(true);


  return <>
    <div className={`app-container bg-white text-dark`}>
      <Navbar />

      <div className='app h-100'>
        <Routes>
          <Route index element={<HomePage funcNav={setShowNav}/>} />
          <Route path='admin'>
            <Route index element={<CompanyDashboard />}></Route>
            <Route path="companies">
              <Route path=':compCode'>
                <Route path='wallets'>
                  <Route path=':walletCode' element={<WalletDashboard />}></Route>
                </Route>
              </Route>
            </Route>
          </Route>
          {/* <Route path="companies" >
            <Route index element={<RequireAuth><CompaniesList /></RequireAuth>} />
            <Route path="add" element={<CompanyForm />} />
            <Route path=":compId">
              <Route index element={<CompanyDetail />} />
              <Route path="wallets">
                <Route path="add" element={<RequireAuth><WalletForm /></RequireAuth>} />
                <Route path=":walletId">
                  <Route index element={<RequireAuth><WalletJoin /></RequireAuth>} />
                  <Route path="dashboard" element={<RequireAuth><WalletDetail /></RequireAuth>} />
                </Route> 
              </Route>
            </Route>
          </Route> */}
          <Route path='users'>
            {/* <Route index element={<RequireAuth><UserList /></RequireAuth>} /> */}
            <Route path=":id">
              <Route index element={<User />} />
              <Route path="update" element={<UserForm />} />
              <Route path="cards">
                <Route index element={<User />} />
                <Route path="add" element={<CardForm />} />
                <Route path=":cardnr">
                  <Route index element={<CardDetail />} />
                  {/*<Route path="topup" element={<TopUpList />} />
                  <Route path="register" element={<RequireAuth><Kassa /></RequireAuth>} />*/}
                </Route> 
                
              </Route>
            </Route>
          </Route>
          <Route path="auth/login" element={<Login />} />
          <Route path="auth/register" element={<Register />} />
          <Route path="auth/pass" element={<PasswordReset />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  </>;
}

export default App;
