import { useCurrentUser } from '../contexts/CurrentUser.context';
import { Link } from 'react-router-dom';



export default function HomePage({funcNav}){

  const {loaded, currentUser} = useCurrentUser();

  return <>
    <div className="container p-2 hero-text mt-4">
      <h1 className="display-1 text-center mt-4">Welkom bij <span className="text-warning">TapWallet</span></h1>
      <p className="lead text-center mt-4">Online betaalkaarten, maar dan gemakkelijk!</p>
      <hr></hr>
      <div className="row mt-5">
        {loaded && currentUser ? <>
          <div className="col">
            <Link to={`/users/${currentUser.userid}`}>
              <button className="btn btn-primary w-100">Mijn account</button>
            </Link>
          </div>
        </> : <Link to="/auth/login"><button className="btn btn-primary w-100">Laten we beginnen!</button></Link>}
      </div>
      <hr className="mt-5"></hr>
      <div className="text-center w-100 mx-auto mt-5">
        <h1>Wat is <span className="text-warning">TapWallet</span>?</h1>
        <p className="mt-5"><span className="text-warning">TapWallet</span> biedt een online drankkaarten systeem zodat je nooit meer met jetons of papieren drankkaarten moet werken.</p>
      </div>
    </div>
  </>;
}