import { memo, useCallback } from 'react';
import {
  IoMoonSharp,
  IoSunny
} from 'react-icons/io5';
import logoFull from '../images/logo_color.png';
import { Link } from 'react-router-dom';
import { useTheme, themes, useThemeColors } from '../contexts/Theme.context';
import { useCurrentUser } from '../contexts/CurrentUser.context';
import LogoutButton from './authentication/LogoutButton';

const NavItem = ({ label, to }) => {
  const { oppositeTheme } = useThemeColors();

  return (
    <li className="nav-item">
      <Link
        to={to}
        className={'nav-link active text-white'}
      >
        {label}
      </Link>
    </li>
  );
};

export default memo(function Navbar() {
  const {currentUser} = useCurrentUser();


  return (<>

    <nav class="navbar" style={{backgroundColor: '#007bb4'}}>
      <div className='d-flex justify-content-between align-items-center w-100'>
        <Link class="navbar-brand ms-3" to="/">
          <img src={logoFull} width="30" height="30" class="d-inline-block align-top" alt=""/>
          <span className='ms-2 text-white navbar-text'>TapWallet</span>
        </Link>
      
        <div className='me-4 text-white'>
          {currentUser ? <>
            <div className='d-flex align-items-center'>
              <Link to="/users/me" className='no-link me-4'>{currentUser.firstname}</Link>
              <LogoutButton />
            </div> </>
            : 
            <>
              <Link to="/auth/login" className='no-link'>Log in</Link>
            </>
          }
        </div>
      </div>
    </nav>
  </>
  );
});