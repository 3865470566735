import { useForm } from "react-hook-form"
import { useCurrentUser } from "../../contexts/CurrentUser.context";
import useAuth from "../../api/auth";
import { useNavigate } from "react-router";

export default function PasswordReset() {

  const {handleSubmit, register} = useForm();

  const {currentUser} = useCurrentUser();
  const {resetPassword, login} = useAuth();
  const navigate = useNavigate()

  const sendPasswords = async (data) => {
    resetPassword({...data, email: currentUser.email})
    navigate("/users/me")
  }

  return <>
    <div className="container mt-4">
      <h2>Stel een nieuw wachtwoord in.</h2>
      <form onSubmit={handleSubmit(sendPasswords)}>
        <div className="mt-4">
          <label>Wachtwoord</label>
          <input className="form-control" type="password" {...register("password")}/>
        </div>
        <div className="mt-4">
          <label>Herhaal wachtwoord</label>
          <input className="form-control" type="password" {...register("repeatPassword")} />
        </div>
        <input type="submit" className="btn btn-primary mt-4 w-100" />
      </form>
    </div>
  </>
}