import { useEffect, useState } from "react";
import useCompanies from "../../api/companie";
import { useCurrentUser } from "../../contexts/CurrentUser.context";
import CompanyDetail from "../companies/CompanyDetail";
import { Link } from "react-router-dom";

export default function CompanyDashboard() {
  const { currentUser } = useCurrentUser();
  const [companies, setCompanies] = useState([]);
  const compAPI = useCompanies();

  if (!currentUser?.dashboards?.length) return;

  return (
    <div className="container mt-4">
      <h1 className="mb-3">Mijn dashboard</h1>
      <p className="text-muted">Bekijk en beheer de bedrijven waar je toegang toe hebt.</p>

      {currentUser?.dashboards.length > 0 ? (
        <div className="row">
          {currentUser?.dashboards.map((company) => (
            <CompanyDetail compId={company} />
          ))}
        </div>
      ) : (
        <p className="text-muted">Je hebt nog geen bedrijven gekoppeld.</p>
      )}
    </div>
  );
}
