import { BsBoxArrowRight } from 'react-icons/bs';
import useAuth from '../../api/auth';
import { useNavigate } from 'react-router';


function LogoutButton() {
  const { logout } = useAuth();
  const navigate = useNavigate()

  const handleLogout = () => {
    logout();
    navigate("/")
  }
  return (
    <BsBoxArrowRight 
      color='#C84758'
      className='clickable'
      size="20"
      onClick={handleLogout}>
      
    </BsBoxArrowRight>
  );
}

export default LogoutButton;
