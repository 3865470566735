import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import useCards, * as cardsAPI from '../../api/cards';
import Error from '../errors/Error';
import Loader from '../loader/Loader';
import TransactionList, { TransactionTable } from '../transactions/TransactionList';
import Card from './Card';
import { Link } from 'react-router-dom';
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs';
import {useLocation, useHistory} from 'react-router-dom';
import { useCurrentUser } from '../../contexts/CurrentUser.context';


export default function CardDetail() {
  const { cardnr } = useParams();
  const location = useLocation();
  const [card, setCard] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ blocked, setBlocked ] = useState(false);
  const [ isGold, setIsGold ] = useState(false);
  const cardsAPI = useCards();
  const { loaded, currentUser} = useCurrentUser();


  const handleBlockCard = useCallback(async () => {
    const opposite = !blocked;
    setBlocked(opposite);
    const result =  await cardsAPI.updateCard(cardnr, {isBlocked: !blocked});
    setCard(result)
  }, [blocked]);

  const handleGoldenCard = useCallback(async () => {
    setIsGold(!isGold);
    const result =  await cardsAPI.updateCard(cardnr, {isGold: !isGold});
    setCard(result);
  }, [isGold]);

  useEffect(() => {
    async function fetchData(){
      setLoading(true);
      try{
        setError(null);
        
        const card = await cardsAPI.getById({cardnr});
        setCard(card);
        setBlocked(card.isBlocked == 0 ? false : true);
        setIsGold(card.isGold == 0 ? false : true);
      }catch(error){
      }finally{
        setLoading(false);
      }
    }
    fetchData();
  }, []);


  return <>
    <div className='container mt-4 pb-4'>
      <Error error={error} />
      <Loader loading={loading} />
      {new URLSearchParams(location.search).get('success') == 'true' ? <>
        <div className="alert alert-success alert-dismissible fade show d-flex align-items-center" role="alert">
          <BsCheckCircleFill color="success" className="me-2"/>
          <div>
          Transactie geslaagd!
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </> : null }
      {new URLSearchParams(location.search).get('success') == 'false' ? <>
        <div className="alert alert-danger alert-dismissible fade show d-flex align-items-center" role="alert">
          <BsXCircleFill color="danger" className="me-2"/>
          <div>
            {new URLSearchParams(location.search).get('msg')}
          </div>
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </> : null }
      
      {!loading && !error ? 
        <>
          <div className='d-flex justify-content-sm-center'>
            <Card key={card.cardnr} companyName={card.companyName} isGold={card.isGold} id={cardnr} amount={card.amount} name={card.name} />
          </div>
          {loaded && currentUser.permissions.includes('write') ? <>
            <Link to={'topup'} state={{waleltId: card.walletId}} className='row'>
              <button className='btn btn-primary col m-4'>Top Up!</button>
            </Link>
            <Link to={'register'} state={{waleltId: card.walletId}} className='row'>
              <button  className='btn btn-primary col mx-4'>Kassa</button>
            </Link>
            <hr />
            <div className="form-check form-switch">
              <input 
                className="form-check-input" 
                type="checkbox" 
                role="switch" 
                id="flexSwitchCheckDefault" 
                checked={isGold}
                onChange={handleGoldenCard}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Gouden kaart</label>
              <p><small>Deze kaart krijgt een onbeperkt limiet</small></p>
            </div>
            </> : null
          }
          {/* <hr></hr>
          <div className="form-check form-switch">
            <input 
              className="form-check-input" 
              type="checkbox" 
              role="switch" 
              id="flexSwitchCheckDefault" 
              checked={blocked}
              onChange={handleBlockCard}
            />
            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Blokkeer deze kaart</label>
            <p><small>Kaart kwijt? Kaart gestolen? Blokkeer deze kaart zodat die niet meer gebruikt kan worden. Als je je kaart terug hebt kan je die gemakkelijk hier terug activeren.</small></p>
          </div> */}
          <hr></hr>
          <h3>Laatste transacties</h3>
          {card?.transactions?.length > 0 ? <>
            <TransactionList transactions={card.transactions}/>
          </> : 
          <div className="alert alert-info mt-4">
              Er zijn nog geen transacties voor deze kaart.
          </div> }
        </>
        : null}
      
      
      
    </div>
  </>;
}