import axios from 'axios';
import {
  useCallback,
} from 'react';
import { baseUrl } from '../helpers/urls';
import { useCurrentUser } from '../contexts/CurrentUser.context';

const useCards = () => {

  const {token} = useCurrentUser()

  const addCard = useCallback(async ({compId, walletId}) => {
    const response = await axios.post(
      `${baseUrl}/companies/${compId}/wallets/${walletId}/cards`,
      {},
      {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [token]);

  const getById = useCallback(async ({cardnr}) => {
    const response = await axios.get(
      `${baseUrl}/cards/${cardnr}`,
      {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  }, [token]);

  return {
    addCard,
    getById
  };
};

export default useCards;