import { useCallback, useEffect, useState } from 'react';
import { BsFillArrowRightCircleFill, BsFillPencilFill, BsSearch, BsTrash } from 'react-icons/bs';
import useUsers from '../../api/users.js';
import { useThemeColors } from '../../contexts/Theme.context.jsx';
import User from './User';
import { Link, useParams } from 'react-router-dom';
import Loader from '../loader/Loader.jsx';
import Error from '../errors/Error'; 
import Forbidden from '../errors/Forbidden.jsx';
import { useForm } from 'react-hook-form';
import useWallets from '../../api/wallets.js';

export function UserTable({
  users,
}) {
  const { theme } = useThemeColors();

  if (users.length === 0) {
    return (
      <div className="alert alert-info">
        There are no users yet.
      </div>
    );
  }

  return (
    <div>
      <table className={`table table-striped table-responsive table-${theme} `}>
        <thead>
          <tr>
            <th className='small'>Voornaam</th>
            <th className='small'>Achternaam</th>
            <th className="d-none d-md-table-cell d-lg-table-cell">Email</th>
            <th className="d-none d-lg-table-cell">Saldo</th>
            <th className='small'>Kaartnr.</th>
            <th className="d-none d-md-table-cell d-lg-table-cell"></th>
            <th className="d-none d-md-table-cell d-lg-table-cell"></th>
          </tr>
        </thead>
        <tbody>
          {users.map((t) => (
            <UserListItem 
              key={t.userid} 
              id={t.userid} 
              firstname={t.firstname} 
              lastname={t.lastname} 
              email={t.email}
              isGold={t.isGold}
              cardnr={t.cardnr}
              amount={t.amount} />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export function UserListItem({key, id, isGold, amount, cardnr, firstname, lastname, email}){
  console.log(firstname)
  const { theme, oppositeTheme } = useThemeColors();
  
  return <>

    <tr key={key}>
      <td className='small'>
        <Link to={`/users/${id}`} className="no-link">
          {firstname}
        </Link>
      </td>
      <td className='small'>
        {lastname}
      </td>
      <td className="d-none d-md-table-cell d-lg-table-cell" >
        {email}
      </td>
      <td className="d-none d-lg-table-cell">
      {isGold ? <>&infin;</> : <>€{amount.toFixed(2)}</>}
        
      </td>
      <td >
        <Link to={`/users/${id}/cards/${cardnr}`} className="no-link small">
          {cardnr}
        </Link>
      </td>
      <td className="d-none d-md-table-cell d-lg-table-cell">
        <Link to={`/users/${id}/update`}>
          <BsFillPencilFill color={'green'}/>
        </Link>
      </td>
      <td className="d-none d-md-table-cell d-lg-table-cell">
        <BsTrash color='red'/>
      </td>
    </tr>
  </>;
}

export default function UserList({users}){
  console.log(users);
  const [ userList, setUserList ] = useState(users);
  const [ loading, setLoading ] = useState(false);
  const [error, setError] = useState(null);
  const [ forbidden, setForbidden ] = useState(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const { walletCode } = useParams();

  const { theme, oppositeTheme } = useThemeColors();

  const usersAPI = useUsers();
  const walletAPI = useWallets();

  const onSearch = useCallback(async (data)=> {
    setLoading(true);
    fetchUsers({walletCode, searchTerm: data.searchTerm})
  },[]);

  const fetchUsers = async ({walletCode, searchTerm})=> {
    try{
      const result = await walletAPI.getUsersOfWallet({walletCode, searchTerm});
      console.log(result);
      setUserList(result.users);
      setLoading(false);
    }catch(error){
      if(error.response.status == 403){
        setForbidden(true);
      }else{
        setError(error);
      }
    }
  };

  useEffect(() => {
    fetchUsers({walletCode, searchTerm: ""});
  }, [])

  return <>
    
    {!forbidden ? <>
      <div className={`card border-0 bg-${theme} text-${oppositeTheme} shadow mb-4 p-4`}>
        <form onSubmit={handleSubmit(onSearch)} className="mb-3">
          <div className="mb-3">
            <label htmlFor="searchTerm" className="form-label">Zoek op email of kaartnummer</label>
            {errors.searchTerm && <p className="form-text text-danger">{errors.searchTerm.message}</p> }
            
            <div className="input-group">
              <div className="input-group-prepend">
                <span className={`input-group-text bg-${theme} text-${oppositeTheme}`}>
                  <BsSearch size={25}></BsSearch>
                </span>
              </div>
              <input
                {...register('searchTerm')}
                defaultValue=''
                id="searchTerm"
                type="text"
                className={`form-control bg-${theme} text-${oppositeTheme}`}
                placeholder="Email"
              />
              <button type="submit" className="btn btn-primary ml-2">Zoeken</button>
            </div>
          </div>
            
        </form>
        <Error error={error} />
          
        <div className='overflow-auto' style={{height: '269px'}}>
        {loading ? 
          <Loader loading={loading} /> 
          : 
          <UserTable users={userList} />}
        </div>
      </div> 
    </> : <Forbidden /> } 
  </>;
}