import {BsAt, BsFillEnvelopeFill, BsFillPlusCircleFill, BsMapFill, BsTrash} from 'react-icons/bs';
import { useState, useEffect, useCallback } from 'react';
import Loader from '../loader/Loader';
import Error from '../errors/Error'; 
import Card from '../cards/Card';
import { useNavigate, useParams } from 'react-router';
import useUsers from '../../api/users';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../contexts/Theme.context';
import { useCurrentUser } from '../../contexts/CurrentUser.context';
import Forbidden from '../errors/Forbidden';
import LogoutButton from '../authentication/LogoutButton';
import ScanModal from '../modal/ScanModal';
import useCards from '../../api/cards';

export default function User() {
  const [thisUser, setThisUser] = useState({});
  const [error, setError] = useState(null);
  const [ showScanner, setShowScanner] = useState(false);
  
  const { currentUser } = useCurrentUser();

  const usersAPI = useUsers();
  const cardsAPI = useCards();
  const navigate = useNavigate();


  const handleAddNewCard = useCallback(async ({walletId, compId}) => {
      try{
        
        const response = await cardsAPI.addCard({compId, walletId});
        if(response.err){
          setError(response);
        }else{
          navigate(`/users/${currentUser.userid}/cards/${response.id}`);
        }
      }catch(error){
        setError(error);
      }
    }, []);

    const showModal = useCallback(() => {
      setShowScanner(true)
    })

    const handleCloseModal = useCallback(() => {
      setShowScanner(false);
    }, []);

  return (
    <div className={'container text-start mt-4'}>
      <Error error={error} />
      <ScanModal onSuccess={handleAddNewCard} show={showScanner} onClose={handleCloseModal}/>

      <div className='row justify-content-start'>
        <div className='col mt-4 mt-md-0'>
          <div className='h-100 w-100 d-flex justify-content-center justify-content-md-start align-items-center'>
            <h1 className='display-4 text-center  text-md-start'>{currentUser.firstname} {currentUser.lastname}</h1>
          </div>
        </div>
      </div>
        <div className='d-flex justify-content-center justify-content-md-start'>
          <BsFillEnvelopeFill className='mt-1 mx-2'/>
          <div >
            <p>{currentUser?.email}</p>
          </div>
        </div>
      <div>
        <div className='d-flex align-items-center mb-3'>
          <h3>Mijn kaarten</h3>
          <BsFillPlusCircleFill className='ms-4 clickable' size={24} color='green' onClick={showModal}/>
        </div>
        {currentUser?.cards?.length > 0 ? 
        <div className='row'>
          {currentUser?.cards?.map(c => (
            <Card key={c.cardnr} companyName={c.companyName} isGold={c.isGold} id={c.cardnr} goto={false} amount={c.amount} name={c.name}/>
          ))}
        </div> : <>
        <div className="alert alert-info mt-4">
            Je hebt nog geen kaarten
        </div></>}
      

      </div>
    </div>
  );
}