import { useEffect, useState } from "react"
import useWallets from "../../api/wallets";
import { useParams } from "react-router";
import QRCode from "react-qr-code";
import { Chart } from "react-google-charts";
import TransactionList from "../transactions/TransactionList";
import UserList from "../users/UserList";

export default function WalletDashboard(){

  const {compCode, walletCode} = useParams();
  const [wallet, setWallet] = useState();
  const walletApi = useWallets()

  useEffect(() => {
    const fetchWallet = async () => {
      const result = await walletApi.getStats({walletCode, month: "", year: ""});
      console.log(result)
      setWallet(result)
    }
    fetchWallet()
  }, [])

  return <>
    <div className="container my-4">
      <h1>Dashboard</h1>
      <div className="row">
        <div className="col-12 col-md-4 p-2">
          <div className="card shadow bg-gradient-1 text-white border border-0 p-2 p-md-4">
            <h6 className="mb-0">Totaal verdiend</h6>
            <h2 className="text-end">€{`${wallet?.revenue ? wallet.revenue.toFixed(2) : "0,00"}`}</h2>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2">
          <div className="card shadow bg-gradient-2 text-white border border-0 p-2 p-md-4">
            <h6 className="mb-0">Aantal kaarten</h6>
            <h2 className="text-end">{wallet?.totalCards || 0}</h2>
          </div>
        </div>

        <div className="col-12 col-md-4 p-2">
          <div className="card shadow bg-gradient-3 text-white border border-0 p-2 p-md-4">
            <h6 className="mb-0">Aantal transacties</h6>
            <h2 className="text-end">{wallet?.totalTransactions || 0}</h2>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6 col-md-4 col-xl-3">
          <div className="card border-0 p-3 shadow h-100 d-flex justify-content-between align-items-center overflow-hidden">
          <h5>QR-code</h5>
          <div className="h-100 w-100 overflow-hidden d-flex justify-content-center align-items-center">
            <QRCode value={`${compCode}/${walletCode}`} size={150}/>
          </div>
          </div>
        </div>
        <div className="col-6 col-md-4 col-xl-3">
          <div className="card border-0 p-3 shadow w-100 h-100 d-flex justify-content-between align-items-center">
          
            <h5>Populair</h5>
            <div className="h-100 w-100 overflow-hidden d-flex jusitfy-content-center align-items-center">
               <Chart 
                chartType="PieChart"
                data={[["Naam", "Hoeveelheid"]].concat(wallet?.sold.map(i => [i.name, Number(i.quantity)]))}
                options={{
                  pieHole: 0.4,
                  sliceVisibilityThreshold: 0.05,
                  pieSliceText: "none",
                  legend: {
                    position: "center",
                    alignment: "center",
                  },
                  chartArea: {
                    width: "100%",  // Zorgt ervoor dat de grafiek zelf breder wordt
                    height: "100%", // Laat de grafiek meer van de container-hoogte benutten
                    top: 0, // Verklein de witruimte bovenaan
                    bottom: 0 // Verklein de witruimte onderaan
                  }
                }}
                width={"100%"}
                height={"150px"} />
            </div>

          </div>
          
        </div>
        <div className="col-12 col-md-4 col-xl-6 mt-4 mt-md-0">
          <div className="card border-0 p-3 shadow w-100 h-100 ">
            <h5>Laatste transacties</h5>
            <TransactionList transactions={wallet?.latestTransactions  || []} />
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          <UserList users={wallet?.users || []} />
        </div>
      </div>
    </div>
  </>
}