import { createContext, useState, useEffect, useContext } from 'react';

export const CurrentUserContext = createContext({
  currentUser: null,
  setCurrentUser: () => {},
  loaded: false,
  setLoaded: () => {},
  token: null,
  setToken: () => {}
});

export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    // Probeer de user op te halen uit localStorage
    const storedUser = localStorage.getItem("currentUser");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState(() => {
    // Probeer de token op te halen uit localStorage
    return localStorage.getItem("token") || "";
  });

  const [loaded, setLoaded] = useState(false);

  // Opslaan in localStorage wanneer de token of user verandert
  useEffect(() => {
    if (currentUser) {
      localStorage.setItem("currentUser", JSON.stringify(currentUser));
    } else {
      localStorage.removeItem("currentUser");
    }
  }, [currentUser]);

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser, loaded, setLoaded, token, setToken }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
