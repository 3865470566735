import axios from 'axios';
import {
  useCallback,
} from 'react';

import { baseUrl } from '../helpers/urls';


const useCompanies = () => {

  const getAll = useCallback(async () => {
    const response = await axios.get(`${baseUrl}/companies`);
 
    return response.data;
  }, []);

  const getById = useCallback(async (id) => {
    const response = await axios.get(`${baseUrl}/companies/${id}`);

    console.log(response);
    return response.data;
  },[]);

  const create = useCallback(async (name, street, number, postalcode, city, country) => {
    const response = await axios({
      method: 'POST',
      url: `${baseUrl}/companies`,
      data: {name, street, number, postalcode, city, country},
    });

    console.log(response.data);
    return response.data;
  }, []);

  const deleteComp = useCallback(async (id) => {
    const response = await axios.delete(`${baseUrl}/companies/${id}`);

    console.log(response);
  },[]);

  return {
    getAll,
    getById,
    create,
    deleteComp
  };
};

export default useCompanies;