import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Wallet from '../wallets/Wallet';
import useCompanies from '../../api/companie';
import useWallets from '../../api/wallets';
import { useCurrentUser } from '../../contexts/CurrentUser.context';

export default function CompanyDetail({ compId }) {
  const [company, setCompany] = useState({ wallets: [] });
  const { loaded, currentUser } = useCurrentUser();
  const companiesAPI = useCompanies();
  const walletApi = useWallets();

  const handleDeleteWallet = useCallback(async (walletId) => {
    try {
      await walletApi.deleteWallet({ compId, walletId });
      const result = await companiesAPI.getById(compId);
      setCompany(result);
    } catch (error) {
      console.error("Fout bij verwijderen van wallet:", error);
    }
  }, [compId, companiesAPI, walletApi]);

  useEffect(() => {
    const getCompany = async () => {
      try {
        const result = await companiesAPI.getById(compId);
        setCompany(result);
      } catch (error) {
        console.error("Fout bij ophalen van bedrijf:", error);
      }
    };
    getCompany();
  }, [compId]);

  return (
    <div className="container pb-4">
      <div className="border-bottom pb-3 mb-4">
        <h2 className="mb-1">{company.name}</h2>
      </div>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h4 className="mb-0">💳 Wallets</h4>
        {loaded && currentUser.permissions.includes('write') && (
          <Link to={`/companies/${company.code}/wallets/add`} className="btn btn-success btn-sm">
            + Nieuwe Wallet
          </Link>
        )}
      </div>

      {company?.wallets?.length > 0 ? (
        <div className="row">
          {company.wallets.map((w) => (
            <div key={w.id} className="col-md-4 mb-3">
              <Wallet 
                company={company}
                wallet={w}
                onDelete={handleDeleteWallet} 
                prefix={`companies/${company.code}`} />
            </div>
          ))}
        </div>
      ) : (
        <div className="alert alert-info mt-3">Dit bedrijf heeft nog geen wallets.</div>
      )}
    </div>
  );
}
