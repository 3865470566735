import { useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form"
import useAuth from "../../api/auth";
import { useNavigate } from "react-router";
import { useCurrentUser } from "../../contexts/CurrentUser.context";
import { Link } from "react-router-dom";
import Error from "../errors/Error";
import imgCard from "../../images/topupcard.png"


export default function Login(){

  const {register, handleSubmit} = useForm();
  const {registerUser }= useAuth();
  const navigate = useNavigate();
  const {currentUser} = useCurrentUser();
  const [errors, setErrors] = useState();

  useEffect(() =>{
    if(currentUser) {
      navigate("/users/me");
    }
  }, [currentUser])

  const handleLogin = async (data) => {
    try {
      await registerUser(data);
      navigate("/users/me");
    } catch (error) {
      console.log(error.response.data.details)
      setErrors(error.response.data.details);
    }
  }

  return <>
  <div className="container mb-4 mt-3">
    <div className="rounded-2 rounded-lg-4 shadow overflow-hidden register-container">
      <div className="row">
      <div className="col-12 col-lg-6 p-0 bg-primary">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <img src={imgCard} className="login-image"/>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="p-4 d-flex flex-column w-100 h-100 justify-content-center align-items-center">
            <h2 className="text-center">Maak een account</h2>
            <form onSubmit={handleSubmit(handleLogin)} className="w-100">
              <div className="row">
                <div className="mt-3 col-12 col-md-6 pe-md-2">
                  <label>Voornaam</label>
                  <input type="text" {...register("firstname")} placeholder="Voornaam" className="form-control"/>
                  {errors?.firstname && <p className="form-text text-danger" data-cy="labelinput-error">{errors.firstname}</p> }
                </div>
                <div className="mt-3 col-12 col-md-6 ps-md-2">
                  <label>Familienaam</label>
                  <input type="text" {...register("lastname")} placeholder="Familienaam" className="form-control"/>
                  {errors?.lastname && <p className="form-text text-danger" data-cy="labelinput-error">{errors.lastname}</p> }

                </div>
              </div>
              <div className="mt-3">
                <label>Email</label>
                <input type="email" {...register("email")} placeholder="Email" className="form-control"/>
                {errors?.email && <p className="form-text text-danger" data-cy="labelinput-error">{errors.email}</p> }

              </div>
              <div className="mt-3">
                <label>Wachtwoord</label>
                <input type="password" {...register("password")} placeholder="Wachtwoord" className="form-control" />
                {errors?.password && <p className="form-text text-danger" data-cy="labelinput-error">{errors.password}</p> }

              </div>
              <div className="mt-3">
                <label>Herhaal wachtwoord</label>
                <input type="password" {...register("passwordRepeat")} placeholder="Herhaal Wachtwoord" className="form-control" />
                {errors?.passwordRepeat && <p className="form-text text-danger" data-cy="labelinput-error">{errors.passwordRepeat}</p> }
              </div>
              <input type="submit" value={"Maak jouw account"} className="btn btn-primary w-100 mt-3"
              />
            </form>

            <p className="text-center mt-3">
              Heb je al een account? Log in <Link to="/auth/login">hier</Link>.
            </p>
          </div>
        </div>
        
      </div>
    </div>
  </div>
    
  </>
}